import React, { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import emailjs from '@emailjs/browser';

import InputForm from '../InputForm/InputForm';
import MessageCard from '../MessageCard/MessageCard';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Sheet_Url, MANDATORY_FIELD_ERROR, EMAILJS_CONFIG} from '../utils/constants';

import './RegisterForm.css';
import 'react-phone-number-input/style.css';

const RegisterForm = ({formRef}) => {

  const [submitMessage, setSubmitMessage] = useState(null);
  const [formData, setFormData] = useState({
    teamName: '',
    phoneNumberContact: '',
    emailContact: '',
    member1Name: '',
    member2Name: '',
    member3Name: '',
    member4Name: ''
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    teamName: '',
    phoneNumberContact: '',
    emailContact: '',
    member1Name: '',
    member2Name: '',
    member3Name: '',
    member4Name: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumberContact: value });
    if (errors.phoneNumberContact) {
      setErrors({ ...errors, phoneNumberContact: '' });
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    return phone && phone.length > 0 && isValidPhoneNumber(phone);
  };

  const validateRequired = (value, minLength) => {
    return value && value.length >= minLength;
  };

  const sendEmail = () => {
    emailjs
      .sendForm(EMAILJS_CONFIG.serviceId, EMAILJS_CONFIG.templateId, formRef.current, {
        publicKey: EMAILJS_CONFIG.publickKey,
      })
      .then(
        () => {
         
        },
        (error) => {
          setLoading(false);
          setSubmitMessage({ text: `Error: ${error.text}. Try again!`, type: 'error' });
          setTimeout(() => setSubmitMessage(null), 5000);
        }
      );
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    let formIsValid = true;
    let newErrors = { 
      teamName: '',
      phoneNumberContact: '',
      emailContact: '',
      member1Name: '',
      member2Name: '',
      member3Name: '',
      member4Name: ''
    };

    if (!formData.teamName) {
        newErrors.teamName = MANDATORY_FIELD_ERROR;
        formIsValid = false;
    } else if (!validateRequired(formData.teamName, 3)) {
      newErrors.teamName = 'Numele echipei trebuie sa aiba cel putin 3 caractere';
      formIsValid = false;
    }

    if (!formData.emailContact) {
      newErrors.emailContact = MANDATORY_FIELD_ERROR;
      formIsValid = false;
    } else if (!validateEmail(formData.emailContact)) {
      newErrors.emailContact = 'Acest format este invalid';
      formIsValid = false;
    }

    if (!formData.phoneNumberContact) {
      newErrors.phoneNumberContact = MANDATORY_FIELD_ERROR;
      formIsValid = false;
    } else if (!validatePhone(formData.phoneNumberContact)) {
      newErrors.phoneNumberContact = 'Acest format este invalid';
      formIsValid = false;
    }

    if (!formData.member1Name) {
        newErrors.member1Name = MANDATORY_FIELD_ERROR;
        formIsValid = false;
    } else if (!validateRequired(formData.member1Name, 3)) {
      newErrors.member1Name = 'Nume membru 1 trebuie sa aiba cel putin 3 caractere';
      formIsValid = false;
    }

    if (!formData.member2Name) {
        newErrors.member2Name = MANDATORY_FIELD_ERROR;
        formIsValid = false;
    } else if (!validateRequired(formData.member2Name, 3)) {
      newErrors.member2Name = 'Nume membru 2 trebuie sa aiba cel putin 3 caractere';
      formIsValid = false;
    }

    if (!formData.member3Name) {
        newErrors.member3Name = MANDATORY_FIELD_ERROR;
        formIsValid = false;
    } else if (!validateRequired(formData.member3Name, 3)) {
      newErrors.member3Name = 'Nume membru 3 trebuie sa aiba cel putin 3 caractere';
      formIsValid = false;
    }

    if (!formData.member4Name) {
        newErrors.member4Name = MANDATORY_FIELD_ERROR;
        formIsValid = false;
    } else if (!validateRequired(formData.member4Name, 3)) {
      newErrors.member4Name = 'Nume membru 4 trebuie sa aiba cel putin 3 caractere';
      formIsValid = false;
    }

    setErrors(newErrors);

    if (formIsValid) {
       setLoading(true);
    try {
      const data = new FormData();
      data.append('teamName', formData.teamName);
      data.append('phoneNumberContact', formData.phoneNumberContact);
      data.append('emailContact', formData.emailContact);
      data.append('member1Name', formData.member1Name);
      data.append('member2Name', formData.member2Name);
      data.append('member3Name', formData.member3Name);
      data.append('member4Name', formData.member4Name);

      await fetch(Sheet_Url, {
        method: 'POST',
        body: data,
        muteHttpExceptions: true,
      });

      setFormData({
        teamName: '',
        phoneNumberContact: '',
        emailContact: '',
        member1Name: '',
        member2Name: '',
        member3Name: '',
        member4Name: ''
      });
      setLoading(false);
      setSubmitMessage({ text: 'Your data was successfully submitted!', type: 'success' });
      setTimeout(() => setSubmitMessage(null), 5000);
      
      sendEmail();
    } catch (error) {
        setLoading(false);
        setSubmitMessage({ text: `Error: ${error.text}. Try again!`, type: 'error' });
        setTimeout(() => setSubmitMessage(null), 5000);
        console.log(error);
    }
    }
  };

  return (
    <div className='register-form-wrapper'>
        {loading && <LoadingSpinner />}
      <div className='register-form-title'>Înscrie-ți Echipa pentru Turneu</div>
     {!submitMessage && <form ref={formRef} className='register-form'>
        <InputForm
          required={true}
          label='Numele echipei'
          name='teamName'
          type='text'
          value={formData.teamName}
          onChange={handleChange}
          error={errors.teamName}
          placeholder='Numele echipei'
        />
        <InputForm
          required={true}
          label='Număr de telefon persoana de contact'
          name='phoneNumberContact'
          type='phone'
          value={formData.phoneNumberContact}
          error={errors.phoneNumberContact}
          placeholder='Telefon persoana de contact'
          onChange={handlePhoneChange}
          defaultCountry='RO'
        />
        <InputForm
          required={true}
          label='Email persoana de contact'
          name='emailContact'
          type='email'
          placeholder='Email persoana de contact'
          value={formData.emailContact}
          error={errors.emailContact}
          onChange={handleChange}
        />
        <div className='form-group-wrapper'>
          <label className='form-label'>
            Membrii echipei *
          </label>
          <div className='form-group-line'>
            <InputForm
              required={true}
              name='member1Name'
              type='text'
              value={formData.member1Name}
              error={errors.member1Name}
              placeholder='Nume membru 1'
              onChange={handleChange}
            />
            <InputForm
              required={true}
              name='member2Name'
              type='text'
              value={formData.member2Name}
              error={errors.member2Name}
              placeholder='Nume membru 2'
              onChange={handleChange}
            />
          </div>
          <div className='form-group-line'>
            <InputForm
              required={true}
              name='member3Name'
              type='text'
              value={formData.member3Name}
              error={errors.member3Name}
              placeholder='Nume membru 3'
              onChange={handleChange}
            />
            <InputForm
              required={true}
              name='member4Name'
              type='text'
              value={formData.member4Name}
              error={errors.member4Name}
              placeholder='Nume membru 4'
              onChange={handleChange}
            />
          </div>
        </div>
        <button className='register-form-button submit-button button' onClick={handleSubmit}>
        Înscrie-te
        </button>
      </form>}
      {submitMessage?.type === 'success' && 
      <MessageCard type={'success'} 
                   title={'Înregistrarea ta în echipă a fost finalizată cu succes'} 
                   description={'Suntem încântați să te avem alături de noi.'}/>}
       {submitMessage?.type === 'error' && 
      <MessageCard type={'error'} 
                   title={'Ups! Error'} 
                   description={submitMessage.text}/>}
    </div>
  );
};

export default RegisterForm;
