
import PhoneInput from 'react-phone-number-input';


const InputForm = ({
  label,
  name,
  type,
  value,
  onChange,
  error,
  placeholder,
  defaultCountry,
  required
}) => {
  return (
    <div className='form-group'>
      {label && <label className='form-label'>
        {label} {required && '*'}
      </label>}
      {type === 'phone' ? (
        <PhoneInput
          international
          defaultCountry={defaultCountry}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      ) : (
        <input
          type={type}
          name={name}
          className='form-input'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}
      {error && (
        <span className='form-error'>
          {error}
        </span>
      )}
    </div>
  );
};

export default InputForm;
