import './Footer.css';
import Logo from '../../assets/images/logoNeoChain.png';

const Footer = () => {
    return (
        <footer className="footer-wrapper">
            <div className="footer-column-wrapper">
                <div><b>Contact</b></div>
                <div>Email: <a href='mailto:3ontopploiesti@gmail.com'>3ontopploiesti@gmail.com</a></div>
                <div>Telefon: 0725742555 - David ; 0721861661 - Calin</div>
            </div>
            
            <div className="footer-row-wrapper" onClick={() => {window.open('https://neochain.ro', '_blank')}}>
                <div className="powered-by">powered by</div>
                <div className="footer-image"><img src={Logo} alt="log"/></div>
            </div>
        </footer>
    );
}

export default Footer;