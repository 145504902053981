import React, { useEffect, useRef } from 'react';
import './HeroBanner.css';
import GlobSrc from '../../assets/images/glob.png';

const leftImageLinks = [
  require('../../assets/sliders/left-1.jpeg'),
  require('../../assets/sliders/left-2.jpeg'),
  require('../../assets/sliders/left-3.jpeg'),
  require('../../assets/sliders/left-4.jpeg'),
  require('../../assets/sliders/right-1.jpeg'),
  require('../../assets/sliders/right-2.jpeg'),
  require('../../assets/sliders/right-3.jpeg'),
];

const rightImageLinks = [
  require('../../assets/sliders/right-1.jpeg'),
  require('../../assets/sliders/right-2.jpeg'),
  require('../../assets/sliders/right-3.jpeg'),
];

const HeroBanner = ({ formRef }) => {
  const leftListRef = useRef(null);
  const rightListRef = useRef(null);

  useEffect(() => {
    const leftScroll = setInterval(() => {
      if (leftListRef.current) {
        leftListRef.current.scrollTop += 1;  // Adjust speed by changing the increment
        // When it scrolls past the first set of images, reset to the middle
        if (leftListRef.current.scrollTop >= leftListRef.current.scrollHeight / 2) {
          leftListRef.current.scrollTop = 0;
        }
      }
    }, 30); // Adjust speed by changing interval

    const rightScroll = setInterval(() => {
      if (rightListRef.current) {
        rightListRef.current.scrollTop -= 1;  // Adjust speed by changing the increment
        // When it scrolls past the second set of images, reset to the middle
        if (rightListRef.current.scrollTop <= 0) {
          rightListRef.current.scrollTop = rightListRef.current.scrollHeight / 2;
        }
        
      }
    }, 30); // Adjust speed by changing interval

    // Start right list from the last element (scroll all the way to the right)
    if (rightListRef.current) {
      rightListRef.current.scrollTop = rightListRef.current.scrollHeight;
    }

    return () => {
      clearInterval(leftScroll);
      clearInterval(rightScroll);
    };
  }, []);

  const handleButtonClick = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior:'smooth', block:'start' });
    }
  };

  return (
    <div className="hero-banner">
      <div className="hero-banner-text">
        <div>
          <div className="title">3 On Top Series</div>
          <div className="title">Ploiești 2024</div>
        </div>
        <p>
          Înscrie-ți echipa acum și alătură-te competiției!
        </p>
        <button className="button" onClick={handleButtonClick}>Înscrie-te</button>
      </div>
      <div className="hero-banner-img-wrapper">
        <div className="left-list" ref={leftListRef}>
          {[...leftImageLinks, ...leftImageLinks].map((imgSrc, index) => (
            <img key={index} src={imgSrc} alt={`left-${index}`} />
          ))}
        </div>
        <div className="right-list" ref={rightListRef}>
          {[...rightImageLinks, ...rightImageLinks].map((imgSrc, index) => (
            <img key={index} src={imgSrc} alt={`right-${index}`} />
          ))}
        </div>
      </div>
      <div className="img-glob-wrapper">
        <img src={GlobSrc} alt="globe" />
      </div>
    </div>
  );
};

export default HeroBanner;
