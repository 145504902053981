import CheckCircle from '../../assets/images/check-circle.png';

import './MessageCard.css';

const MessageCard = ({type, title, description}) => {
    return (
        <div className="message-card-wrapper">
           {type === 'success' && <div className="message-card-icon">
                <img src={CheckCircle} alt="check circle"></img>
            </div>}
            <div className="message-card-title">
                {title}
            </div>
            <div className="message-card-description">{description}</div>
        </div>
    )
}

export default MessageCard;