import React from 'react';

import SpinnerSrc from '../../assets/images/basketBall.png';
import './LoadingSpinner.css';

const LoadingSpinner = () => {
    return (
        <div className="spinner">
            <img src={SpinnerSrc} alt="Loading..." className="rotating-image" />
        </div>
    );
};

export default LoadingSpinner;
