import React, { useRef } from "react";

import HeroBanner from "../../HeroBanner/HeroBanner";
import RegisterForm from "../../RegisterForm/RegisterForm";
import ImageWithText from "../../ImageWithText/ImageWithText";

import "./Homepage.css";

const Homepage = () => {
    const formRef = useRef();
    return (
       <div className="homepage-wrapper">
            <HeroBanner formRef={formRef}/>
            <ImageWithText/>
            <RegisterForm formRef={formRef}/>
       </div>
    )
}

export default Homepage